/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { PageBlock } from '@/components/blocks/Page';
import { useEventTracker } from '@/data/EventTracker';
import { useLayout } from '@/data/Layout';
import { useStyleTheme } from '@/styles/theme';
import { FC } from 'react';
import { AdvantageUserMembershipProvider } from '@/data/context/advMembershipFlag';
import { useUrlPromoCode } from '@/data/Content/urlParamsCookies';
import AppDynamicsInitializerComponent from '@/components/blocks/Appdynamics';
import { useAppDynamicsData } from '@/data/Content/AppDynamics';
import { useMeta } from '@/data/Meta';

export const Page: FC = () => {
    const { meta } = useMeta();
    const { layout } = useLayout();
    const { theme, additives } = useStyleTheme();
    useEventTracker();
    useUrlPromoCode();
    const { appKey } = useAppDynamicsData();

    return (
        <AdvantageUserMembershipProvider>
            {appKey ? <AppDynamicsInitializerComponent appKey={appKey} /> : null}
            <PageBlock
                meta={meta}
                layout={layout}
                theme={theme}
                additives={additives}
            />
        </AdvantageUserMembershipProvider>
    );
};
