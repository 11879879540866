import { useNextRouter } from '@/data/Content/_NextRouter';
import { useSettings } from '@/data/Settings';
import { useUserContext } from '@/data/UserContext';
import { PARAMETER_COOKIES } from '@/data/constants/cookies';
import { DATA_KEY_PROMOCODE } from '@/data/constants/dataKey';
import { Cache } from '@/data/types/Cache';
import { generateKeyMatcher } from '@/data/utils/generateKeyMatcher';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import Cookies from 'cookies';
import { getCookie, setCookie } from 'cookies-next';
import { customMarketing } from 'integration/generated/custom';
import { GetServerSidePropsContext } from 'next';
import useSWR, { mutate } from 'swr';

export const DATA_KEY = DATA_KEY_PROMOCODE;

type GetCookieProps = {
	context: GetServerSidePropsContext;
	cookieConfig: { dataKey: string; maxAge: number; paramName: string };
	cache: Cache;
};

type GetContextProps = {
	context: GetServerSidePropsContext;
	cache: Cache;
};

type GetUpdateCacheProps = {
	context: GetServerSidePropsContext;
	cache: Cache;
	value: string | undefined;
	dataKey: string;
	hasKey: boolean;
};

export const updateCache = async ({
	context,
	cache,
	value,
	dataKey,
	hasKey,
}: GetUpdateCacheProps) => {
	if (!value && hasKey) {
		const cookie = new Cookies(context.req, context.res);
		cache.set(dataKey, cookie.get(dataKey));
	} else if (value) {
		cache.set(dataKey, value);
	}
};

const customEventFetcher =
	(pub: boolean) => async (DM_ReqCmd: string, PromoCode: string, storeId: string) => {
		try {
			return await customMarketing(pub).eventTriggerMarketing(storeId ?? '', undefined, {
				DM_ReqCmd,
				PromoCode,
			});
		} catch (e) {
			return undefined;
		}
	};

export const setGenericCookie = ({ cookieConfig, context, cache }: GetCookieProps) => {
	const { dataKey, maxAge, paramName } = cookieConfig;
	const paramValue = context.query[paramName];
	const cookieKeys = Object.keys(context.req.cookies);
	const hasKey = cookieKeys.includes(dataKey);
	const cookie = new Cookies(context.req, context.res);
	const options = { maxAge, overwrite: true, httpOnly: true };
	let valueForCache = undefined;
	if (paramValue) {
		cookie.set(dataKey, paramValue as string, options);
		valueForCache = paramValue;
	}
	updateCache({
		context,
		cache,
		value: valueForCache as string | undefined,
		dataKey: cookieConfig.dataKey,
		hasKey,
	});
};

export const setPromoCodeCookie = async ({ cookieConfig, context, cache }: GetCookieProps) => {
	const { dataKey, maxAge, paramName } = cookieConfig;
	const paramValue = context.query[paramName];
	const cookieKeys = Object.keys(context.req.cookies);
	const hasKey = cookieKeys.includes(dataKey);
	const cookie = new Cookies(context.req, context.res);
	const options = { maxAge, overwrite: true, httpOnly: false };
	let cookiePromoCodeValue = undefined;
	if (paramValue) {
		if (!hasKey) {
			cookiePromoCodeValue = paramValue;
		} else {
			const splits = cookie.get(dataKey)?.split('|');
			if (!splits?.includes(paramValue as string)) {
				cookiePromoCodeValue = `${cookie.get(dataKey)}|${paramValue}`;
			}
		}
		if (cookiePromoCodeValue) {
			cookie.set(dataKey, cookiePromoCodeValue as string, options);
		}
		updateCache({
			context,
			cache,
			value: paramValue as string | undefined,
			dataKey: cookieConfig.dataKey,
			hasKey,
		});
	}
};

export const getUrlParamCookies = async ({ context, cache }: GetContextProps) => {
	// setPromoCodeCookie({ cookieConfig: PARAMETER_COOKIES[0], context, cache });
	setGenericCookie({ cookieConfig: PARAMETER_COOKIES[1], context, cache });
	setGenericCookie({ cookieConfig: PARAMETER_COOKIES[2], context, cache });
};

export const useUrlPromoCode = () => {
	const { settings } = useSettings();
	const { data: promoCode } = useSWR(PARAMETER_COOKIES[0].dataKey);
	const { user } = useUserContext();
	const router = useNextRouter();
	const { storeId, langId, defaultCatalogId: catalogId } = getClientSideCommon(settings, router);
	const { query } = router;
	const { PromoCode } = query; // getting promoCode from client side router
	const promoCodeValue = PromoCode as string;
	const promoCodesInCookie = getCookie(PARAMETER_COOKIES[0].dataKey);
	const storedPromoCodes = promoCodesInCookie?.split('|') ?? [];
	const checkPromoCodeInStoredPromoCodes = storedPromoCodes?.includes(promoCodeValue);

	const addPromoCodeToCookie = (promoCode: string) => {
		const options = {
			maxAge: 172800000,
			overwrite: true,
			httpOnly: false,
			encode: (value: string): string => value,
		};
		if (promoCodesInCookie) {
			// Split by the vertical pipe and check for the current promo code
			if (!storedPromoCodes?.includes(promoCode)) {
				// Append the new promo code and update the cookie
				const updatedPromoCodes = [...storedPromoCodes, promoCode]
					.filter(Boolean) // Remove any empty values
					.join('|');
				setCookie(PARAMETER_COOKIES[0].dataKey, updatedPromoCodes, options); // Expires in 1 day
			}
		} else {
			// If no promo code cookie exists, create one with the promo code from the URL
			setCookie(PARAMETER_COOKIES[0].dataKey, promoCode, options);
		}
	};

	useSWR(
		promoCodeValue && user
			? [
					{
						storeId,
						promoCodeValue,
					},
			  ]
			: null,

		async ([{ storeId, promoCodeValue }]) =>
			customEventFetcher(true)('PromoCodeMarketingEvent', promoCodeValue, storeId),
		{
			onSuccess: () => {
				addPromoCodeToCookie(promoCodeValue);
				mutate(generateKeyMatcher({ ['ESpotDataFromName']: true })(''));
			},
		}
	);
};
